import { useState } from "react";
import "./main-page.scss";


const MainPage = () => {

    const [phone,setPhone] = useState('');
    const [message,setMessage] = useState('');


    const sendMessage = () => {
        if(phone === '' || message === ''){
            return
        }else{
            const encoded = encodeURI(message);
            const url = `https://api.whatsapp.com/send?phone=91${phone}&text=${encoded}`;
            window.open(url);
        }
    }

return(
    <div className="main-page">
        <div className="input-content">
            <div>
            <div className="input">
                <div className="form-group">
                    <label htmlFor="inputNumber">Number</label>
                    <input type="text" className="input-text" onChange={(e) => setPhone(e.target.value)}/>
                </div>
                <div className="form-group">
                <label htmlFor="inputNumber">Message</label>
                    <textarea type="text" rows="10" className="input-text" onChange={(e) => setMessage(e.target.value)}/>
                </div>
                <div className="form-group">
                    <button className="send-message" onClick={sendMessage}>Send Message</button>
                </div>
            </div>
            </div>
        </div>
    </div>
)
}

export default MainPage;